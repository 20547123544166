
.light-mode {
  --nav-background-color: var(--nav-light-background-color);
  --nav-hovered-background-color: var(--nav-light-hovered-background-color);
  --nav-expanded-background-color: var(--nav-light-expanded-background-color);
  --nav-text-color: var(--nav-light-text-color);
  --nav-hovered-text-color: var(--nav-light-hovered-text-color);
  --nav-expanded-text-color: var(--nav-light-expanded-text-color);
  --nav-disabled-text-color: var(--nav-light-disabled-text-color);
  --nav-border-color: var(--nav-light-border-color);
}

html {
  scroll-behavior: smooth;
}

#banner {
  grid-area: banner;
  height: var(--banner-height);
  background-color: var(--banner-color);
  z-index: 4;
}

html.goog-tbar > body > #page-grid > #rail {
  top: calc(var(--translate-height));
}

html.goog-tbar > body > #page-grid > #search-bar > #search-bar-wrapper {
  top: calc(var(--translate-height));
}

html.goog-tbar > body > #page-grid > #breadcrumbs-bar > #breadcrumbs-bar-wrapper {
  top: calc(var(--translate-height) + var(--search-bar-height));
}

html.goog-tbar > body > #page-grid > #rail {
  top: calc(var(--translate-height));
}

html.goog-tbar > body > #page-grid > #search-bar > #search-bar-wrapper {
  top: calc(var(--translate-height));
}

html.goog-tbar > body > #page-grid > #breadcrumbs-bar > #breadcrumbs-bar-wrapper {
  top: calc(var(--translate-height) + var(--search-bar-height));
}

#rail {
  grid-area: rail;
  width: calc(var(--nav-rail-width) + var(--nav-rail-submenu-width));
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--nav-background-color);
  box-sizing: border-box;
  -webkit-box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.2), 3px 0 4px 0 rgba(0, 0, 0, 0.14), 3px 0 3px -2px rgba(0, 0, 0, .12);
  -moz-box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.2), 3px 0 4px 0 rgba(0, 0, 0, 0.14), 3px 0 3px -2px rgba(0, 0, 0, 0.12);
  box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.2), 3px 0 4px 0 rgba(0, 0, 0, 0.14), 3px 0 3px -2px rgba(0, 0, 0, 0.12);
  z-index: 7;
  transition: all .5s;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#rail::-webkit-scrollbar {
  display: none;
}

#rail.collapsed {
  width: var(--nav-rail-width);
}

#rail > .items {
  height: 100%;
  width: var(--nav-rail-width);
  position: relative;
  box-sizing: border-box;
}

#rail.expanded > .items {
  border-right: 2px solid var(--nav-border-color);
}

#rail > .items > .item {
  display: flex;
  flex-direction: column;
  width: calc(var(--nav-rail-width) - 8px);
  margin: 4px;
  border-radius: 8px;
}

#rail > .items .item-link:hover {
  background-color: var(--nav-hovered-background-color);
}

#rail > .items .selected.item, .light-mode #rail > .items .selected.item-link:hover {
  padding-right: 0;
  background-color: var(--nav-accent-color-lighter);
}

#rail > .items > .item .item-label-text, #rail > .items > .item .subitems {
  display: inline-block;
  overflow: hidden;
}

#rail > .items > .item .nav-item-title-text {
  font-weight: bold;
}

#rail.expanded > .item .item-label-text, #rail.expanded > .item .subitems {
  width: 100%;
}

#rail > .items .item .item-label {
  display: flex;
  align-items: center;
  color: var(--nav-text-color);
  height: 100%;
  padding: 8px 4px;
  margin: 0;
  text-decoration: none;
  flex-direction: column;
  background-color: transparent;
  border: none;
}

#rail > .items > .item > .subitems {
  width: var(--nav-rail-submenu-width);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: var(--nav-rail-width);
  transition: all .5s;
  overflow-y: auto;
}

#rail > .items .item .item-label-text, #rail > .items .item .item-label-text > span {
  color: var(--nav-hovered-text-color);
}

#rail > .items .item.selected > .item-label, #rail > .items .item.selected > .item-label > .item-label-text, #rail > .items .item .item.selected {
  color: var(--nav-accent-color-contrast);
  fill: var(--nav-accent-color-contrast);
  font-weight: 600;
}

#rail > .items .item.selected > .item-label > .nav-rail-icon {
  fill: var(--nav-accent-color-contrast);
}

#rail > .items .item.selected > .item-label > .item-label-text.nav-item-title-text {
  font-weight: bold;
}

#rail > .items > .item .item {
  display: flex;
  align-items: center;
  overflow: visible;
  color: #C1C1C1;
  text-decoration: none;
  font-size: 14px;
  margin: 8px 12px;
  border-radius: 8px;
}

#rail > .items > .item .item .item-label {
  width: 100%;
  padding: 16px 8px;
  align-items: flex-start;
}

#rail > .items .item > .item-label > .item-label-text {
  font-size: 15px;
  text-align: center;
  transition: all .5s;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  word-break: break-word;
}

#rail > .items .item > .subitems .item > .item-label > .item-label-text {
  text-align: left;
}

#search-bar {
  grid-area: search-bar;
  height: var(--search-bar-height);
}

#search-bar-wrapper {
  position: fixed;
  width: calc(100% - var(--nav-rail-width));
  height: var(--search-bar-height);
  top: var(--search-bar-top);
  display: flex;
  z-index: 6;
  background-color: var(--nav-background-color);
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  transition: left .5s;
}

#search-bar-actions {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

#search-bar-actions .search-bar-action {
  margin-left: 2px;
  display: flex;
  align-items: center;
}

#search-bar-actions .search-bar-action a .font-icon, #search-bar-actions .search-bar-action button .font-icon {
  color: var(--nav-text-color);
}

#search-bar-actions .search-bar-action a .font-icon:hover, #search-bar-actions .search-bar-action a .material-icons:hover, #search-bar-actions .search-bar-action button .font-icon:hover, #search-bar-actions .search-bar-action button .material-icons:hover {
  color: var(--nav-hovered-text-color);
}

#search-bar-actions #customer-warnings a:hover {
  opacity: 0.8;
}

#search-bar-actions .search-bar-action .iconButton {
  opacity: 1.0;
}

#warnings {
  grid-area: warnings;
  height: var(--warnings-bar-height);
}

#warnings-wrapper {
  position: fixed;
  width: calc(100% - var(--nav-rail-width));
  height: var(--warnings-bar-height);
  top: var(--warnings-bar-top);
  z-index: 5;
}

#breadcrumbs-bar {
  grid-area: breadcrumbs-bar;
  height: var(--breadcrumbs-bar-height);
}

#breadcrumbs-bar-wrapper {
  position: fixed;
  width: calc(100% - var(--nav-rail-width));
  height: var(--breadcrumbs-bar-height);
  top: var(--breadcrumbs-bar-top);
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 8px;
  z-index: 4;
  background-color: var(--banner-color);
  color: var(--banner-color-contrast);
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  transition: left .5s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#breadcrumbs {
  display: flex;
  align-items: center;
  overflow: hidden;
}

#breadcrumbs .breadcrumb {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 24px;
  max-width: 844px;
}

#breadcrumbs .breadcrumb, #breadcrumbs .separator {
  color: var(--banner-color-contrast-faded);
}

#breadcrumbs .breadcrumb:last-of-type {
  color: var(--banner-color-contrast);
}

#breadcrumbs-bar-wrapper a:hover {
  text-decoration: underline;
}

#breadcrumbs-bar-wrapper .breadcrumb, #breadcrumbs-bar-wrapper a, #breadcrumbs-bar-wrapper a.iconButton:hover, #breadcrumbs-bar-wrapper a.iconButton:focus, #breadcrumbs-bar-wrapper a[role="button"]:hover {
  text-decoration: none;
}

#breadcrumbs .breadcrumb:first-of-type, #breadcrumbs .breadcrumb:last-of-type, #breadcrumbs .separator {
  flex: 0 0 auto;
}

#breadcrumbs .breadcrumb:first-of-type {
  flex-shrink: 1;
}

#breadcrumbs-bar-wrapper #pageActions {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#breadcrumbs-bar-content {
  width: calc(var(--page-controls-width) + var(--content-width) - 24px);
  display: flex;
  align-items: center;
}

#breadcrumbs-bar-wrapper #pageActions svg, #breadcrumbs-bar-wrapper #pageActions .iconButton, #breadcrumbs-bar-wrapper #pageActions button, #breadcrumbs-bar-wrapper #pageActions a[role="button"] {
  fill: var(--banner-color-contrast-faded);
  color: var(--banner-color-contrast-faded);
}

#breadcrumbs-bar-wrapper #pageActions .iconButton {
  opacity: 1;
}

#breadcrumbs-bar-wrapper #pageActions .iconButton:hover svg, #breadcrumbs-bar-wrapper #pageActions .iconButton:hover, #breadcrumbs-bar-wrapper #pageActions button:hover, #breadcrumbs-bar-wrapper #pageActions a[role="button"]:hover {
  fill: var(--banner-color-contrast);
  color: var(--banner-color-contrast);
}

#page-controls {
  grid-area: page-controls;
  background-color: var(--body-background-color);
  width: var(--page-controls-width);
  z-index: 2;
}

#page-controls #page-controls-expand-wrapper {
  width: 24px;
  position: fixed;
  top: var(--page-content-top);
  bottom: var(--footer-height);
  left: calc(var(--nav-rail-width) + var(--page-controls-width) - 24px);
  z-index: 12;
}

body.no-page-controls #page-controls #page-controls-expand-wrapper {
  display: none;
}

.page-controls-collapsed #page-controls #page-controls-expand-wrapper {
  left: calc(var(--nav-rail-width) + var(--page-controls-width) - 30px);
}

#page-controls #page-controls-expand-wrapper #page-controls-expand-button {
  width: 24px;
  height: 24px;
  background-color: #737373;
  color: #FFF;
  opacity: 1;
  position: absolute;
  top: 106px;
  z-index: 13;
}

#page-controls #page-controls-expand-wrapper:hover #page-controls-expand-button, #page-controls #page-controls-expand-wrapper #page-controls-expand-button:focus {
  background-color: var(--accent-color-light);
  color: var(--accent-color-contrast-faded);
}

#page-controls #page-controls-expand-wrapper #page-controls-expand-button .material-icons:after {
  content: "chevron_left";
}

#page-grid.page-controls-collapsed #page-controls #page-controls-expand-wrapper #page-controls-expand-button .material-icons:after {
  content: "chevron_right";
}

#page-controls #page-controls-expand-wrapper #page-controls-expand-button + #page-controls-edge {
  height: 100%;
  width: 2px;
  border-style: solid;
  border-color: transparent;
  border-width: 0 0 0 2px;
  opacity: 0.54;
  position: absolute;
  left: 10px;
  z-index: 12;
}

#page-grid.page-controls-collapsed #page-controls #page-controls-expand-wrapper #page-controls-expand-button + #page-controls-edge {
  border-color: #737373;
}

#page-grid #page-controls #page-controls-expand-wrapper:hover #page-controls-expand-button + #page-controls-edge, #page-grid #page-controls #page-controls-expand-wrapper #page-controls-expand-button:focus + #page-controls-edge {
  border-color: var(--accent-color-light);
}

#page-controls-filter-badge {
  border-radius: 50%;
  border: 1px solid white;
  background-color: #C42B1C;
  height: 18px;
  width: 18px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 13;
  font-size: small;
  color: white;
  padding-left: 1px;
  padding-bottom: 1px;
  position: absolute;
  top: 94px;
  left: 12px;
}

#page-grid.page-controls-collapsed {
  --page-controls-width: 44px;
}

#page-grid.page-controls-collapsed #page-controls-filter-badge {
  display: flex;
}

#page-controls-wrapper {
  height: 100%;
  width: calc(var(--page-controls-width) - 32px);
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

#page-controls-wrapper.fixed {
  height: calc(100vh - var(--search-bar-height) - var(--warnings-bar-height) - var(--breadcrumbs-bar-height) - var(--footer-height));
  position: fixed;
  overflow-y: hidden;
  padding-right: 4px;
  top: var(--page-content-top);
  z-index: 10;
}

#page-controls-wrapper.full {
  height: calc(100vh - var(--page-content-top) - var(--footer-height));
  position: static;
}

#page-grid.page-controls-collapsed #page-controls-wrapper {
  height: 0;
  width: 0;
  padding: 0;
  overflow: hidden;
}

#page-controls-wrapper .page-control-widget {
  width: calc(var(--page-controls-width) - 48px);
  display: flex;
  flex-direction: column;
  background-color: var(--paper-background-color);
  box-sizing: border-box;
  margin: 8px 0 8px 0;
  padding: 12px;
}

#page-controls-wrapper .page-control-widget.paper {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
  background-color: var(--paper-background-color);
}

#page-controls-wrapper .page-control-widget.paper.elevation1 {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
}

.responsiveContentPanelDrawerContents .page-control-widget-nested.paper,
#page-controls-wrapper .page-control-widget.page-control-widget-nested.paper {
  padding: 0;
  border: none;
  box-shadow: none;
}

#page-controls-wrapper .page-control-widget:first-child {
  margin-top: 25px;
}

#page-controls-wrapper .page-control-widget:last-child {
  margin-bottom: 16px;
}

#page-controls-wrapper.full .page-control-widget {
  width: inherit;
  height: inherit;
  border-radius: 0 0 4px 0;
  margin: 0;
}

#page-controls-wrapper .page-control-widget.fixed {
  position: sticky;
  top: var(--page-content-top);
  z-index: 10;
}

#page-controls-wrapper.full .page-control-widget.fixed {
  position: fixed;
  top: auto;
}

#page-controls-wrapper .page-control-widget.sidebarGroup {
  margin-top: 36px;
}

.page-control-widget .page-control-widget-header {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
}

.page-control-widget .page-control-widget-header:last-child {
  margin-bottom: 0;
}

.page-control-widget .page-control-widget-content {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 4px;
}

.scrollable.page-control-widget .page-control-widget-content, .scrollable.page-control-widget.page-control-widget-content {
  overflow-y: auto;
}

#page-controls-wrapper .scrollable.page-control-widget {
  /* Remove the padding on the widget if it's scrollable so the scrollbar is right at the edge of the widget */
  padding-right: 0;
}

#page-controls-wrapper .scrollable.page-control-widget .page-control-widget-content > *, #page-controls-wrapper .scrollable.page-control-widget.page-control-widget-content > * {
  /* Then add the right padding back in on the direct children of the content so they aren't right against the edge */
  padding-right: 12px;
}

.page-control-widget .widget-label {
  display: block;
  color: #333;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 4px 0 8px 0;
  line-height: 1;
}

.page-control-widget-header .widget-label, .page-control-widget .filterLabel {
  margin: 0;
}

.page-control-input input[type="text"], .page-control-input select {
  display: block;
  width: calc(100% - 8px);
  outline: none;
  border-width: 0 0 1px 0;
  margin: 0 0 1px 0;
  font: inherit;
  font-size: 1.1rem;
  font-weight: 500;
  font-variant-numeric: proportional-nums lining-nums;
}

.page-control-input select {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.page-control-input > input[type="text"] {
  padding: 6px 0 7px 0;
  margin-bottom: 0;
  background-color: transparent;
  width: 100%;
}

.page-control-input select:hover, .page-control-input select:focus {
  border-width: 0 0 2px 0;
  margin-bottom: 0;
}

.page-control-input input[type="text"]:focus, .page-control-input select:focus {
  border-color: #333;
}

#questionTopControls {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

#questionFilters {
  flex-direction: column;
  width: 100%;
}

#questionFiltersContent #questionFilters {
  align-items: unset;
}

#questionFiltersActions {
  align-self: flex-end;
  margin-top: 8px;
}

#questionFiltersContent .questionExpansionControls {
  margin-bottom: 0;
}

.finalizeButtonContainer {
  margin-top: 8px;
}

#page-content {
  grid-area: page-content;
  background-color: var(--body-background-color);
  z-index: 0;
  width: var(--content-width);
  padding-bottom: calc(var(--footer-height));
  top: var(--page-content-top);
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

#contentContainer {
  padding-right: 4px;
  font-size: large;
  line-height: 1.193;
}

#toolbarArea {
  height: 44px;
  position: sticky;
  top: var(--page-content-top);
  z-index: 2;
}

table.stickyTable {
  border-collapse: separate;
}

.stickyTable thead {
  position: sticky;
  top: calc(var(--code-content-top) - 9px);
  background-color: var(--body-background-color);
  z-index: 10;
}

.lightbox .stickyTable thead {
  top: 0;
}

.codeTable table.stickyTable {
  border-top-width: 0;
}

.codeTable table.stickyTable thead th {
  border-top-width: 1px;
  border-top-style: solid;
}

.codeTable.lightbox {
  margin-top: 0;
}

#codeContent {
  margin-top: 8px;
  position: relative;
}

#page-content * {
  scroll-margin-top: var(--page-content-top);
}

#usersAdminUserArea, #usersAdminUserArea * {
  scroll-margin-top: var(--page-content-top);
}

#usersNormalUserArea, #usersNormalUserArea * {
  scroll-margin-top: var(--page-content-top);
}

#codeContent * {
  scroll-margin-top: calc(var(--code-content-top) + 16px);
}

#codeContent .notesTitle {
  scroll-margin-top: var(--page-content-top);
}

#codeContent .contentTitle {
  scroll-margin-top: calc(var(--code-content-top) - 16px);
}

#codeContent .litem {
  scroll-margin-top: calc(var(--code-content-top) - 8px);
}

.toolbar-sticky #codeContent .contentTitle {
  scroll-margin-top: calc(var(--code-content-top) - 26px);
}

.scrollable.page-control-widget {
  max-height: calc(100% - 32px);
  overflow: auto;
}

#page-content #page-content-wrapper {
  box-sizing: border-box;
  min-height: calc(100vh - var(--page-content-top) - var(--footer-height));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0;
}

#page-extra-space {
  grid-area: page-extra-space;
  background-color: var(--body-background-color);
  min-height: calc(100vh - var(--page-content-top) - var(--footer-height));
  z-index: 2;
}

#page-extra-space #page-extra-space-wrapper {
  box-sizing: border-box;
  padding-left: 8px;
}

@media (min-width: 1240px) {
  .responsive #footer #footer-wrapper {
    width: 940px;
  }
}

@media (min-width: 760px) and (max-width: 995px) {
  .responsive #footer #footer-wrapper {
    width: 700px;
  }
}

@media (max-width: 759px) {
  .responsive #footer #footer-wrapper {
    width: 100%;
  }
}

#footer {
  grid-area: footer;
  background-color: #333;
  color: #999;
  height: var(--footer-height);
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
}

#page-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "rail search-bar search-bar search-bar"
    "rail warnings warnings warnings"
    "rail breadcrumbs-bar breadcrumbs-bar breadcrumbs-bar"
    "rail page-controls page-content page-extra-space"
    "rail footer footer footer";
  grid-template-columns: var(--nav-rail-width) var(--page-controls-width) var(--content-width) auto;
  transition: all .5s;
}

#footer #footerContent {
  width: var(--content-width);
  max-height: var(--footer-height);
  position: relative;
  margin-left: calc(var(--page-controls-width) + var(--nav-rail-width));
  margin-right: auto;
  display: flex;
  display: -ms-flexbox;
  -webkit-align-items: baseline;
  align-items: center;
}

#footer #footerContent .tos {
  max-height: var(--footer-height);
}

#banner #banner-wrapper {
  background-image: url(https://ecode360.com/customization/XX9100/banner?ts=1,661,951,765,424);
  background-position: center;
  background-size: var(--content-width) var(--banner-height);
  background-repeat: no-repeat;
  position: relative;
  margin-left: var(--page-controls-width);
  margin-right: auto;
  height: var(--banner-height);
  overflow: hidden;
  width: var(--content-width);
  transition: all .5s;
  left: 0;
}

#rail > .items > .item.nav-expand.expanded {
  background-color: var(--nav-expanded-background-color);
}

#rail > .items > .item.nav-expand.expanded.selected {
  background-color: var(--nav-accent-color-lighter);
}

#rail > .items > .item.nav-expand .subitems {
  display: block;
}

#rail > .items > .item.nav-expand.collapsed .subitems {
  display: none;
}

.rail-icon {
  height: 32px;
  width: 32px;
  pointer-events: none;
}

.item-icon-path {
  fill: var(--nav-text-color);
}

.nav-rail-icon {
  height: 32px;
  width: 32px;
  fill: var(--nav-text-color);
}

.item > .item-label, .item.nav-expand > .item-label {
  cursor: pointer;
}

.item > .item-label.item-label-not-link, .item.nav-expand > .item-label.item-label-not-link {
  cursor: default;
}

.toc-widget ul {
  list-style-type: none;
  margin: 0;
}

.toc-widget .toc-node ul {
  margin: 0 0 0 16px;
}

.toc-widget .toc-node > span, .toc-widget .toc-node > a {
  display: block;
  font-size: 1rem;
  padding: 0 0 0 1.25rem;
  text-indent: -1.25rem;
}

.toc-widget .toc-node.depth-0 > span, .toc-widget .toc-node.depth-0 > a {
  font-size: 1.25rem;
}

.toc-widget .toc-node.depth-1 > span, .toc-widget .toc-node.depth-1 > a {
  font-weight: 600;
}

.toc-widget .toc-node > span .label-prefix, .toc-widget .toc-node > a .label-prefix {
  font-weight: 500;
  color: #666;
  font-variant-numeric: tabular-nums lining-nums;
}

.toc-widget .toc-node.selected > span, .toc-widget .toc-node.selected > a {
  background-color: #E1E1E1;
}

.toc-widget .toc-node:not(.widget-label,.selected,.currentLocation) > span:hover, .toc-widget .toc-node:not(.widget-label,.selected,.currentLocation) > a:hover {
  background-color: #F2F2F2;
}

/* Rules for using icons as white on a dark background. */
#rail .font-icon, #rail .material-icons {
  color: var(--nav-text-color);
}

#rail a:hover .font-icon, #rail a:hover .material-icons, #rail button:hover .font-icon, #rail button:hover .material-icons, #rail a.font-icon:hover, #rail a.material-icons:hover, #rail button.font-icon:hover, #rail button.material-icons:hover {
  color: var(--nav-hovered-text-color);
}

#rail .font-icon.md-inactive, #rail .material-icons.md-inactive, #rail a:hover .font-icon.md-inactive, #rail a:hover .material-icons.md-inactive, #rail a.font-icon.md-inactive:hover, #rail a.material-icons.md-inactive:hover {
  color: var(--nav-text-color);
}

#rail .item.selected > .item-label > .font-icon, #rail .item.selected > .item-label > .material-icons {
  color: var(--nav-accent-color-contrast);
  fill: var(--nav-accent-color-contrast);
}

#rail .item.selected:hover > .item-label > .font-icon, #rail .item.selected:hover > .item-label > .material-icons {
  color: var(--nav-accent-color-contrast);
  fill: var(--nav-accent-color-contrast);
}

.archiveVersion {
  color: red;
}

.versionBreadcrumb .archiveVersion {
  font-weight: 500;
}

.archiveVersion.muiChip {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #BBB;
}

#breadcrumbs .versionBreadcrumb {
  flex: 0 0 auto;
}

.version-selector svg {
  fill: var(--banner-color-contrast-faded);
}